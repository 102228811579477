import { Link } from 'gatsby';
import Img from 'gatsby-image';
import * as _ from 'lodash';
import { lighten } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../styles/colors';
import { PageContext } from '../templates/post';

const ContentCardStyles = css`
  // flex: 1 1 300px;
  display: block;
  // flex-direction: column;
  overflow: hidden;
  margin: 0 20px 20px;
  min-height: 250px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
`;

const ContentCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const ContentCardImage = styled.div`
  width: auto;
  height: 200px;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
`;

const ContentCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 25px 0;
`;

const ContentCardContentLink = css`
  position: relative;
  flex-grow: 1;
  display: block;
  padding: 25px 25px 0;
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`;

const ContentCardTags = styled.span`
  display: block;
  margin-bottom: 4px;
  color: ${colors.midgrey};
  font-size: 1.2rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const ContentCardTitle = styled.h2`
  margin-top: 0;
`;

const ContentCardExcerpt = styled.section`
  font-family: Georgia, serif;
`;

const ContentCardMeta = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 25px 25px;
`;

export interface ContentCardProps {
}

const ContentCard: React.FC<ContentCardProps> = (props) => {
  return (
    <article
      className={`post-card`}
      css={ContentCardStyles}
    >
      <ContentCardContent className="content-card-content">
         {props.children}
      </ContentCardContent>
    </article>
  );
};

export default ContentCard;
