import { graphql } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import Subscribe from '../components/subscribe/Subscribe';
import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import ContentCard from '../components/ContentCard';
import PostCard from '../components/PostCard';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import config from '../website-config';
import Img from 'gatsby-image';

import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';
import { PageContext } from './post';

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-of-type(4n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(4n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
  }
`;

export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    header: {
      childImageSharp: {
        fluid: any;
      };
    };
    puregymLogo: {
      childImageSharp: {
        fluid: any;
      };
    };
    activeNavLogo: {
      childImageSharp: {
        fluid: any;
      };
    };
    i2oLogo: {
      childImageSharp: {
        fluid: any;
      };
    };
    fitnessFirstLogo: {
      childImageSharp: {
        fluid: any;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const IndexPage: React.FC<IndexProps> = props => {
  const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
  const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fluid.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && <meta name="google-site-verification" content={config.googleSiteVerification} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fluid.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width} />
        <meta property="og:image:height" content={height} />
      </Helmet>
      <Wrapper>
        <header
          css={[outer, SiteHeader]}
          style={{
            backgroundImage: `url('${props.data.header.childImageSharp.fluid.src}')`,
          }}
        >
          <div css={inner}>
            <SiteHeaderContent>
              <SiteTitle>
                {props.data.logo ? (
                  <img
                    style={{ maxHeight: '150px' }}
                    src={props.data.logo.childImageSharp.fixed.src}
                    alt={config.title}
                  />) : (
                  config.title
                )}
              </SiteTitle>
              {props.data.logo ? <SiteTitle>{config.title}</SiteTitle> : null }
              <SiteDescription>{config.description}</SiteDescription>
            </SiteHeaderContent>
            <SiteNav isHome />
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              <ContentCard>
                <h1>We empower fast growing tech organizations to cultivate <strong>happier</strong>, more <strong>effective teams</strong>.</h1>
                <p>We help you to drive visionary success and believe in a future where every tech organization can reach its full potential through the power of well-integrated, happy teams. Let&apos;s build this future together.</p>
                <h2>Empowering your journey towards extraordinary</h2>
                <p>With every challenge comes an opportunity for growth. Our approach provides the hope and guidance for your teams to navigate the complexities of growth, transforming challenges into stepping stones for success.</p>
                <h2>Simplify your path to excellence</h2>
                <p>With our straightforward patterns, principles and decision rules, we help you simplify complex team dynamics, making it easier to nurture a culture of collaboration and high performance.</p>
                <h2>Drive growth through empowered teams</h2>
                <p>By empowering your teams with autonomy and the right tools to continually evolve, your organization&apos;s productivity can soar, directly impacting your bottom line.</p>
                <h2>Transform chaos into order</h2>
                <p>Our expertise turns the chaos of scaling into a structured ascent towards organizational clarity, where every team member knows their role in driving success.</p>
                <h2>Mitigate risks with smarter team structures</h2>
                <p>Avoid the pitfalls of rapid scaling with our proven strategies for team organization and evolution, protecting your investment in your people and your future.</p>
                <h2>Unlock the full potential of your teams</h2>
                <p>Gain access to cutting-edge insights and ways of working that empower your leaders and their teams to exceed their own expectations.</p>
              </ContentCard>
              {props.data.allMarkdownRemark.edges.filter(post => post.node.frontmatter.toplevel === true)
                .map(post => {
                  // filter out drafts in production
                  return (
                    (post.node.frontmatter.draft !== true ||
                      process.env.NODE_ENV !== 'production') &&
                      post.node.fields !== null && (
                      <PostCard key={post.node.fields.slug} post={post.node} />
                    )
                  );
                })}
              <ContentCard>
                <h2>Who have we worked with?</h2>
                <p>We&apos;ve worked with clients from all over the world ranging in both size and shape, here are just a few of them...</p>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="column">
                    <Img style={{ width: '200px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}
                      fluid={props.data.puregymLogo.childImageSharp.fluid} />
                  </div>
                  <div className="column">
                    <Img style={{ width: '350px', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto' }}
                      fluid={props.data.activeNavLogo.childImageSharp.fluid} />
                  </div>
                </div>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="column">
                    <Img style={{ width: '200px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}
                      fluid={props.data.i2oLogo.childImageSharp.fluid} />
                  </div>
                  <div className="column">
                    <Img style={{ width: '200px', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto' }}
                      fluid={props.data.fitnessFirstLogo.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <h2>What do they say about us?</h2>
                <p>We pride ourselves on our commitment to delivering high quality services that get the results our clients need. Here are just some of the comments we&apos;ve received over the years...</p>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="column">
                    <p>&quot;Conjurer Solutions do an excellent job of keeping up to date with the latest technology and approaches and they know how to apply this to real life situations. They are a very safe pair of hands and I could always trust them to deliver. I would highly recommend them.&quot;<br/><br/><strong>Jason Maclellan, Delivery Manager, Fitness First</strong></p>
                  </div>
                  <div className="column">
                    <p>&quot;Conjurer Solutions have expert skills, a real passion for quality, and a very engaging manner. This combination made them both highly respected by our technical team members and very approachable for non technical people.&quot;<br/><br/><strong> Don Aitkenhead, CIO, Aitkenhead Consulting</strong></p>
                  </div>
                </div>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="column">
                    <p>&quot;Conjurer Solutions cultivated the skills and motivation needed to change how we work so that our upfront investment continued to grow.&quot;<br/><br/><strong>Rich Hale, CTO, Active Navigation</strong></p>
                  </div>
                  <div className="column"/>
                </div>
              </ContentCard>
            </div>
          </div>
        </main>
        {props.children}
        {config.showSubscribe && <Subscribe />}
        {/* <Pagination currentPage={props.pageContext.currentPage} numPages={props.pageContext.numPages} /> */}
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "img/conjurer-icon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    header: file(relativePath: { eq: "img/BLACK_I_desktop-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    puregymLogo: file(relativePath: { eq: "img/puregym_logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    activeNavLogo: file(relativePath: { eq: "img/active_navigation_logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2oLogo: file(relativePath: { eq: "img/i2o_logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fitnessFirstLogo: file(relativePath: { eq: "img/fitness_first_logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { draft: { ne: true } } },
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            toplevel
            date
            tags
            draft
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 90) {
                      src
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
